import {LOGIN_ADMIN} from '../actions/types';

const initialState = {
  admin: false,
  adminToken: '',
  loadingAdmin: true,
};

export default function admins(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case LOGIN_ADMIN:
      localStorage.setItem('adminTko', payload.token);
      return {
        ...state,
        admin: true,
        adminToken: payload,
        loadingAdmin: false,
      };
    default:
      return state;
  }
}
