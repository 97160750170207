import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const CandidateRoute = ({
  component: Component,
  candidates: {candidate},
  ...rest
}) => {
  const candidateToken = localStorage.getItem('candidate');
  const CustomRedirect = ({rest, props}) => {
    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}candidate/register`;
    return null;
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        candidateToken ? (
          <Component {...props} />
        ) : (
          <CustomRedirect rest={rest} props={props} />
        )
      }
    />
  );
};

CandidateRoute.propTypes = {
  candidates: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
});
export default connect(mapStateToProps, {})(CandidateRoute);
