import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const PrivateRoute = ({
  component: Component,
  admins: {admin, loadingAdmin},
  auth: {isAuthenticated, loading},
  ...rest
}) => {
  const CustomRedirect = ({rest, props}) => {
window.location.href =
        process.env.REACT_APP_URL_AUTH + '?src=' + window.location.href;
    return null;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <CustomRedirect rest={rest} props={props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  admins: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admins: state.admins,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(PrivateRoute);
