import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  grid: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    fontSize: '1rem',
  },
}));

const UserHeader = ({ user }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActions>
        <Grid container spacing={2}>
          <Grid className={classes.grid} item xs={6}>
            <Link underline='always' href='/dashboard' color='textPrimary'>
              Back to All Candidates
            </Link>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Link
              underline='always'
              href={`/results/${user.link}`}
              color='textPrimary'
            >
              Hasil DISC Test
            </Link>
            <Link
              underline='always'
              href={`/users/edit/${user.link}`}
              color='textPrimary'
            >
              Application Form
            </Link>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

UserHeader.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserHeader;
