import {setAlert} from './alert';
import {
  GET_POSITIONS,
  GET_POSITION,
  ADD_POSITION,
} from './types';
import axios from 'axios';

export const addPosition = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}positions`,
      payload
    );
    dispatch({
      type: ADD_POSITION,
      payload: res.data.data[0],
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const editPosition = (payload) => async (dispatch) => {
  try {
    const {name, notionId, open, PK} = payload;
    const res = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}positions/${PK}`,
      {name: name, open: open, notionId: notionId}
    );
    const data = res.data.data;
    dispatch({
      type: GET_POSITIONS,
      payload: data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const deletePosition = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}positions/${payload.PK}`
    );
    const data = res.data.data;
    dispatch({
      type: GET_POSITIONS,
      payload: data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const getPositionById = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}positions/${payload}`
    );
    dispatch({
      type: GET_POSITION,
      payload: res.data.data[0],
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const getPositions = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}positions`
    );
    const data = res.data.items;
    dispatch({
      type: GET_POSITIONS,
      payload: data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.mesage,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};
