import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const CurrencyFormatted = ({ salary }) => {
  return (
    <Fragment>
      {Number.isInteger(salary * 1) && salary
        ? new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(salary)
        : salary}
    </Fragment>
  );
};

CurrencyFormatted.propTypes = {
  salary: PropTypes.object.isRequired,
};

export default CurrencyFormatted;
