import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import PrivateRoute from './components/routing/PrivateRoute.js';
import CandidateRoute from './components/routing/CandidateRoute.js';
import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/layout/Alert';

import Dashboard from './components/pages/Dashboard';
import UsersAdd from './components/pages/users/UsersAdd';
import UsersEdit from './components/pages/users/UsersEdit';
import UsersResult from './components/pages/users/UsersResult';
import Questions from './components/pages/Questions';
import Positions from './components/pages/positions/Positions';
import PositionsAdd from './components/pages/positions/PositionsAdd';
import PositionsEdit from './components/pages/positions/PositionsEdit';

import Register from './components/pages/candidates/Register';
import CandidateDashboard from './components/pages/candidates/CandidateDashboard';
import CandidateProfile from './components/pages/candidates/CandidateProfile';
import CandidateApplication from './components/pages/candidates/CandidateApplication';
import CandidateDISC from './components/pages/candidates/CandidateDISC';

import { candidateCheck } from './actions/users';

import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import store from './store';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
}

if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(loadUser());
} else {
  // window.location.href = config.AUTH_URL + '?src=' + window.location.href;
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  useEffect(() => {
    const isCandidate = window.location.pathname.includes('candidate');
    if (isCandidate) {
      store.dispatch(candidateCheck());
    }
  }, []);
  const classes = useStyles();
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={1}>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <Header />
            <VerticalNav />
            <main className={classes.content}>
              <Toolbar />
              <Alert />
              <Switch>
                {/* {process.env.REACT_APP_IS_DEV == 'true' ? (
                  <Route exact path='/' component={NoDashboard}></Route>
                ) : ( */}
                <Route exact path='/' component={Register}></Route>
                {/* )} */}
                <Route
                  exact
                  path='/candidate/register'
                  component={Register}
                ></Route>
                <PrivateRoute
                  exact
                  path='/dashboard'
                  component={Dashboard}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/users/add'
                  component={UsersAdd}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/users/edit/:id'
                  component={UsersEdit}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/results/:id'
                  component={UsersResult}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/questions'
                  component={Questions}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/positions'
                  component={Positions}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/positions/add'
                  component={PositionsAdd}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/positions/edit/:id'
                  component={PositionsEdit}
                ></PrivateRoute>

                <CandidateRoute
                  exact
                  path='/candidate/dashboard'
                  component={CandidateDashboard}
                ></CandidateRoute>
                <CandidateRoute
                  exact
                  path='/candidate/profile'
                  component={CandidateProfile}
                ></CandidateRoute>
                <CandidateRoute
                  exact
                  path='/candidate/apply'
                  component={CandidateApplication}
                ></CandidateRoute>
                <CandidateRoute
                  exact
                  path='/candidate/disc'
                  component={CandidateDISC}
                ></CandidateRoute>
              </Switch>
            </main>
          </div>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
