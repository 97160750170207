import React, {Fragment} from 'react';

import Button from '@material-ui/core/Button';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const RegisterForm = ({values, handleSubmit, handleChange, classes}) => {
  return (
    <Fragment>
      <ValidatorForm
        noValidate
        onSubmit={(e) => handleSubmit(e)}
        onError={(errors) => console.log(errors)}
      >
        <TextValidator
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='username'
          label='Email'
          name='username'
          value={values.username}
          autoFocus
          onChange={(e) => handleChange(e)}
          validators={['required', 'trim']}
          errorMessages={['the field is required']}
        />
        <TextValidator
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='password'
          label='Password'
          name='password'
          type='password'
          value={values.password}
          autoFocus
          onChange={(e) => handleChange(e)}
          validators={['required', 'trim']}
          errorMessages={['the field is required']}
        />
        <Button
          fullWidth
          type='submit'
          variant='contained'
          color='primary'
          className={classes().submit}
        >
          Kirim kode verifikasi
        </Button>
      </ValidatorForm>
    </Fragment>
  );
};

RegisterForm.propTypes = {};

export default RegisterForm;
