import {GET_DOWNLOAD, GET_DOCUMENTS} from '../actions/types';

const initialState = {
  documents: [],
  download: {},
  loadingDownload: true,
  error: {},
};

export default function download(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_DOCUMENTS:
      const availableData = state.documents.filter(
        (document) => document.document !== payload.document
      );
      return {
        ...state,
        documents: [...availableData, payload],
        loadingDownload: false,
      };
    case GET_DOWNLOAD:
      return {
        ...state,
        download: payload,
        loadingDownload: false,
      };
    default:
      return state;
  }
}
