import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import MaterialTable from 'material-table';
import tableIcons from '../../elements/TableIcons';

import { getPositions, deletePosition } from '../../../actions/positions';

const Positions = ({
  positions: { positions, loadingPosition },
  getPositions,
  deletePosition,
}) => {
  const history = useHistory();

  const [DataToDelete, setDataToDelete] = useState({});
  const [OpenDialog, setOpenDialog] = useState(false);

  let items = [];
  useEffect(() => {
    getPositions();
  }, [getPositions]);
  if (!loadingPosition && positions.length > 0) {
    positions.forEach((item, index) => {
      let itemToShow = item;
      itemToShow.openField = itemToShow.open ? 'open' : 'close';
      items.push(itemToShow);
    });
    console.log(items);
  }

  const tableOptions = {
    pageSize: 5,
    pageSizeOptions: [5, 10, 15, 20],
    actionsColumnIndex: -1,
  };

  const onClickEdit = (data) => {
    history.push(`/positions/edit/${data.PK}`);
  };

  const onClickDelete = (data) => {
    setOpenDialog(true);
    setDataToDelete(data);
  };
  const onDialogClose = () => {
    setDataToDelete({});
    setOpenDialog(false);
  };
  const onDialogConfirm = () => {
    deletePosition(DataToDelete);
    setOpenDialog(false);
    setDataToDelete({});
  };
  const clickNewPosition = () => {
    history.push('/positions/add');
  };

  const tableActions = [
    {
      icon: tableIcons.Add,
      tooltip: 'Tambah Posisi',
      isFreeAction: true,
      onClick: (event) => clickNewPosition(),
    },
    (rowData) => ({
      icon: tableIcons.Edit,
      tooltip: 'Ubah Posisi',
      onClick: (event, rowData) => onClickEdit(rowData),
    }),
    (rowData) => ({
      icon: tableIcons.Delete,
      tooltip: 'Hapus Posisi',
      onClick: (event, rowData) => onClickDelete(rowData),
    }),
  ];

  return (
    <Fragment>
      <MaterialTable
        options={tableOptions}
        icons={tableIcons}
        columns={[
          {
            title: 'Position',
            field: 'name',
            headerStyle: { fontWeight: 'bold' },
          },
          {
            title: 'Status',
            field: 'openField',
            headerStyle: { fontWeight: 'bold' },
          },
        ]}
        data={items}
        // cellEditable={{
        //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
        //     return new Promise((resolve, reject) => {
        //       onClickEdit({
        //         newValue: newValue,
        //         rowData: rowData,
        //         columnDef: columnDef,
        //       });
        //       setTimeout(resolve, 1000);
        //     });
        //   },
        // }}
        actions={tableActions}
        title='Data Posisi'
      ></MaterialTable>
      <Dialog
        open={OpenDialog}
        onClose={onDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Hapus posisi?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Anda yakin ingin menghapus posisi ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color='secondary'>
            Batalkan
          </Button>
          <Button onClick={onDialogConfirm} color='secondary'>
            Ya hapus
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

Positions.propTypes = {
  getPositions: PropTypes.func.isRequired,
  deletePosition: PropTypes.func.isRequired,
  positions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  positions: state.positions,
});

export default connect(mapStateToProps, { getPositions, deletePosition })(
  Positions
);
