import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {CardContent} from '@material-ui/core';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const LoginForm = ({
  values,
  handleSubmit,
  handleChange,
  handleChangeState,
  classes,
}) => {
  return (
    <Card variant='outlined'>
      <CardHeader
        title='Login'
        subheader='Silakan masukan email dan password untuk Login'
      ></CardHeader>
      <CardContent>
        <ValidatorForm
          noValidate
          onSubmit={(e) => handleSubmit(e)}
          onError={(errors) => console.log(errors)}
        >
          <TextValidator
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='username'
            label='Email'
            name='username'
            value={values.username}
            autoFocus
            onChange={(e) => handleChange(e)}
            validators={['required', 'trim']}
            errorMessages={['the field is required']}
          />
          <TextValidator
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='password'
            label='Password'
            name='password'
            type='password'
            value={values.password}
            autoFocus
            onChange={(e) => handleChange(e)}
            validators={['required', 'trim']}
            errorMessages={['the field is required']}
          />
          <Button
            fullWidth
            type='submit'
            variant='contained'
            color='primary'
            className={classes().submit}
          >
            Login
          </Button>
        </ValidatorForm>
        <Grid className={classes().Link} container>
          <Grid item xs={12}>
            <Link
              href='#'
              onClick={(e) => handleChangeState('register')}
              variant='body1'
              color='textSecondary'
            >
              Belum punya akun? Register
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
