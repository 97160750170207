import {GET_QUESTIONS} from './types';

export const getQuestions = () => async (dispatch) => {
  try {
    const data = [
      {
        a: 'Terbuka, Mudah setuju',
        b: 'Percaya, Mudah percaya pada orang',
        c: 'Petualang, berani mengambil Resiko',
        d: 'Toleran, Menghormati',
      },
      {
        a: 'Volume suara cenderung pelan, Pendiam',
        b: 'Optimistic, Visioner',
        c: 'Menjadi pusat perhatian, Suka bergaul',
        d: 'Pendiam, Membawa harmoni',
      },
      {
        a: 'Menyemangati orang',
        b: 'Berusaha sempurna',
        c: 'Bagian dari kelompok / berkomunitas',
        d: 'Ingin membuat tujuan',
      },
      {
        a: 'Mudah Frustasi',
        b: 'Menyimpan perasaan saya',
        c: 'Menceritakan sisi saya',
        d: 'Siap beroposisi',
      },
      {
        a: 'Bersemangat, Suka bicara',
        b: 'Gerak cepat, Tekun',
        c: 'Berusaha menjaga keseimbangan',
        d: 'Berusaha mengikuti aturan',
      },
      {
        a: 'Mengelola waktu secara efisien',
        b: 'Sering terburu-buru, Merasa tertekan',
        c: 'Masalah sosial itu penting',
        d: 'Menyelesaikan apa yang dimulai',
      },
      {
        a: 'Tolak perubahan mendadak',
        b: 'Cenderung janji berlebihan',
        c: 'Tarik diri di tengah tekanan',
        d: 'Tidak takut beroposisi ',
      },
      {
        a: 'Penyemangat yang baik',
        b: 'Pendengar yang baik',
        c: 'Penganalisa yang baik',
        d: 'Delegator yang baik',
      },
      {
        a: 'Mengutamakan hasil',
        b: 'Melakukan dengan benar, Akurasi benar',
        c: 'Menyenangkan',
        d: 'Selalu mengerjakan bersama',
      },
      {
        a: 'Akan berjalan terus tanpa kontrol diri',
        b: 'Akan membeli sesuai dorongan hati',
        c: 'Bisa menunggu, Tanpa tekanan',
        d: 'Akan mengusahakan yang diinginkan',
      },
      {
        a: 'Ramah, Mudah berbaur',
        b: 'Unik, Bosan rutinitas',
        c: 'Aktif mengubah sesuatu',
        d: 'Ingin hal-hal yang pasti',
      },
      {
        a: 'Jarang konfrontasi, Menyerah ',
        b: 'Dipenuhi hal detail',
        c: 'Perubahan pada menit terakhir',
        d: 'Menuntut, Kasar',
      },
      {
        a: 'Ingin kemajuan',
        b: 'Puas dengan segalanya',
        c: 'Terbuka mengekspresikan perasaan',
        d: 'Rendah hati, Sederhana',
      },
      {
        a: 'Tenang, Pendiam',
        b: 'Bahagia, Tanpa beban',
        c: 'Menyenangkan, Baik hati',
        d: 'Tak gentar, Berani',
      },
      {
        a: 'Menggunakan waktu berkualitas dengan teman',
        b: 'Rencana masa depan, Bersiap',
        c: 'Berpergian demi petualangan baru',
        d: 'Menerima ganjaran atas tujuan yang dicapai',
      },
      {
        a: 'Aturan perlu dipertanyakan',
        b: 'Aturan membuat adil',
        c: 'Aturan membuat bosan',
        d: 'Aturan membuat aman',
      },
      {
        a: 'Pendidikan, Kebudayaan',
        b: 'Prestasi, Apresiasi',
        c: 'Keselamatan, Keamanan',
        d: 'Kehidupan sosial, komunitas',
      },

      {
        a: 'Memimpin, Pendekatan langusng',
        b: 'Suka bergaul, Antusias',
        c: 'Dapat diprediksi, Konsisten',
        d: 'Waspada, Hati-hati',
      },

      {
        a: 'Tidak mudah dikalahkan',
        b: 'Kerjakan sesuai perintah, Ikut pemimpin',
        c: 'Impulsif, Riang',
        d: 'Ingi segalanya teratur, Rapi',
      },

      {
        a: 'Saya akan pimpin mereka',
        b: 'Saya akan melaksanakan',
        c: 'Saya akan menyakinkan mereka',
        d: 'Saya dapatkan fakta',
      },

      {
        a: 'Memikirkan orang dahulu',
        b: 'Kompetitif. Suka tantangan',
        c: 'Optimis, Positif',
        d: 'Pemikir Logis, Sistematik',
      },

      {
        a: 'Menyenangkan orang, Mudah setuju',
        b: 'Tertawa lepas, Hidup',
        c: 'Berani, Tak gentar',
        d: 'Tenang, Pendiam',
      },

      {
        a: 'Ingin otoritas lebih',
        b: 'Ingin kesempatan baru',
        c: 'Menghindari konflik',
        d: 'Ingin pentunjuk yang jelas',
      },

      {
        a: 'Dapat diandalkan, Dapat dipercaya',
        b: 'Kreatif, Unik',
        c: 'Orientasi pada hasil, berprinsip',
        d: 'Standard yang tinggi, Akurat',
      },
    ];
    dispatch({
      type: GET_QUESTIONS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};
