import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { getPositionById, editPosition } from '../../../actions/positions';
const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: 0,
  },
}));

const PositionsEdit = ({
  positions: { position, loadingPosition },
  match,
  getPositionById,
  editPosition,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: '',
    notionId: '',
    open: true,
    PK: '',
  });
  const id = match.params.id;
  useEffect(() => {
    if (formData.name == '') {
      getPositionById(id);
      setFormData({
        PK: loadingPosition || !position.PK ? '' : position.PK,
        name: loadingPosition || !position.name ? '' : position.name,
        notionId:
          loadingPosition || !position.notionId ? '' : position.notionId,
        open: loadingPosition || !position.open ? false : position.open,
      });
    }
  }, [getPositionById, id, position]);
  const onFormChange = (e) => {
    switch (e.target.type) {
      case 'checkbox':
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
        break;
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    editPosition(formData);
    history.push('/positions');
  };

  return (
    <Fragment>
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6' component='h1'>
            Ubah Posisi
          </Typography>
        </CardContent>
      </Card>
      <ValidatorForm
        noValidate
        onSubmit={(e) => onFormSubmit(e)}
        onError={(errors) => console.log(errors)}
      >
        <Card variant='outlined'>
          <CardContent>
            <TextValidator
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Posisi'
              name='name'
              value={formData.name}
              autoFocus
              onChange={(e) => onFormChange(e)}
              validators={['required', 'trim']}
              errorMessages={['the field is required']}
            />
            <TextValidator
              variant='outlined'
              margin='normal'
              fullWidth
              id='notionId'
              label='Notion ID'
              helperText='contoh Notion Id dari https://www.notion.so/krafthaus/QA-c5d9ca077f8946c7b01094904796b4c2 adalah c5d9ca077f8946c7b01094904796b4c2'
              name='notionId'
              value={formData.notionId}
              autoFocus
              onChange={(e) => onFormChange(e)}
              validators={['trim']}
            />
            <FormControlLabel
              className={classes.label}
              control={
                <Switch
                  checked={formData.open}
                  onChange={(e) => onFormChange(e)}
                  name='open'
                  color='primary'
                />
              }
              labelPlacement='start'
              label='Buka Posisi'
            />
          </CardContent>
          <CardActions>
            <Button variant='contained' color='primary' type='submit'>
              Save
            </Button>
          </CardActions>
        </Card>
      </ValidatorForm>
    </Fragment>
  );
};

PositionsEdit.propTypes = {
  getPositionById: PropTypes.func.isRequired,
  editPosition: PropTypes.func.isRequired,
  positions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  positions: state.positions,
});

export default connect(mapStateToProps, { getPositionById, editPosition })(
  PositionsEdit
);
