import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {DropzoneDialog} from 'material-ui-dropzone';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';

import {candidateUpdate} from '../../../actions/users';
import {getPositions} from '../../../actions/positions';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    minWidth: 240,
  },
  typography: {
    marginTop: theme.spacing(2),
  },
}));

const CandidateApplication = ({
  candidates: {candidate},
  candidatesProfile: {profile, loadingProfile},
  positions: {positions, loadingPosition},
  candidateUpdate,
  getPositions,
  handleNext,
  handleBack,
}) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    position: '',
    source_LinkedIn: false,
    source_Social_Media: false,
    source_Company_Website: false,
    source_Internal_Employee: false,
    source_Job_Platform: false,
    startingDate: '1 Month Notice',
    expectedSalary: '',
    source: [],
    resumeDocument: [],
    supportingDocument: [],
    source_Other: '',
    otherStartingDate: '',
  });
  useEffect(() => {
    getPositions();
  }, [getPositions]);
  useEffect(() => {
    if (profile && !loadingProfile) {
      setFormData({
        position: profile && profile.position ? profile.position : '',
        source_LinkedIn:
          profile && profile.source_LinkedIn ? profile.source_LinkedIn : false,
        source_Social_Media:
          profile && profile.source_Social_Media
            ? profile.source_Social_Media
            : false,
        source_Company_Website:
          profile && profile.source_Company_Website
            ? profile.source_Company_Website
            : false,
        source_Internal_Employee:
          profile && profile.source_Internal_Employee
            ? profile.source_Internal_Employee
            : false,
        source_Job_Platform:
          profile && profile.source_Job_Platform
            ? profile.source_Job_Platform
            : false,
        startingDate:
          profile && profile.startingDate
            ? profile.startingDate
            : '1 Month Notice',
        expectedSalary:
          profile && profile.expectedSalary ? profile.expectedSalary : '',
        source: profile && profile.source ? profile.source : [],
        resumeDocument:
          profile && profile.resumeDocument ? profile.resumeDocument : [],
        supportingDocument:
          profile && profile.supportingDocument
            ? profile.supportingDocument
            : [],
        source_Other:
          profile && profile.source_Other ? profile.source_Other : '',
        otherStartingDate:
          profile && profile.otherStartingDate ? profile.otherStartingDate : '',
      });
    }
  }, [profile]);

  const [openResume, setOpenResume] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const {
    position,
    source_LinkedIn,
    source_Social_Media,
    source_Company_Website,
    source_Internal_Employee,
    source_Job_Platform,
    startingDate,
    expectedSalary,
    source,
    resumeDocument,
    supportingDocument,
    source_Other,
    otherStartingDate,
  } = formData;
  const handleChange = (e) => {
    const {name, type, value} = e.target;
    switch (type) {
      case 'checkbox':
        let sourceToUpdate = formData.source;
        if (true === e.target.checked) {
          sourceToUpdate = sourceToUpdate.push(value);
        } else {
          sourceToUpdate = sourceToUpdate.filter((f) => {
            return f != value ? f : '';
          });
        }
        setFormData({...formData, [name]: e.target.checked});
        break;
      default:
        setFormData({...formData, [name]: value});
        break;
    }
  };
  const onSubmit = (e) => {
    let sourceFinal = [];
    formData.source.forEach((source) => {
      const sourceTemp = source.replaceAll(' ', '_');
      if (formData[`source_${sourceTemp}`]) {
        sourceFinal.push(source);
      }
    });
    formData.source = sourceFinal;
    candidateUpdate({type: 'save', candidate: candidate, newData: formData});
    handleNext();
  };

  return (
    !loadingProfile &&
    !loadingPosition && (
      <Container>
        <Card className={classes.card}>
          <ValidatorForm
            noValidate
            onSubmit={(e) => onSubmit(e)}
            onError={(errors) => console.log(errors)}
          >
            <CardHeader title='Application Form'></CardHeader>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <strong>Apply for position</strong>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      fullWidth
                      labelid='position-label'
                      id='position'
                      value={position}
                      onChange={handleChange}
                      name='position'
                      validators={['required', 'trim']}
                      errorMessages={['the field is required']}
                    >
                      {positions.map((item) => {
                        return item.open ? (
                          <MenuItem key={item.PK} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ) : (
                          <Fragment></Fragment>
                        );
                      })}
                    </SelectValidator>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <strong>How did you hear about us</strong>
                  </Typography>
                  <FormControl
                    component='fieldset'
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={source_LinkedIn}
                            name='source_LinkedIn'
                            value='LinkedIn'
                            onChange={handleChange}
                          />
                        }
                        label='LinkedIn'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={source_Social_Media}
                            name='source_Social_Media'
                            value='Social Media'
                            onChange={handleChange}
                          />
                        }
                        label='Social Media'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={source_Company_Website}
                            name='source_Company_Website'
                            value='Company Website'
                            onChange={handleChange}
                          />
                        }
                        label='Company Website'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={source_Internal_Employee}
                            name='source_Internal_Employee'
                            value='Internal Employee'
                            onChange={handleChange}
                          />
                        }
                        label='Internal Employee'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={source_Job_Platform}
                            name='source_Job_Platform'
                            value='Job Platform (Jobstreet, Techinasia, JobsDB, etc)'
                            onChange={handleChange}
                          />
                        }
                        label='Job Platform (Jobstreet, Techinasia, JobsDB, etc)'
                      />
                      <TextField
                        value={source_Other}
                        onChange={handleChange}
                        id='source_Other'
                        name='source_Other'
                        label='Other'
                        fullWidth
                      ></TextField>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <strong>Available Starting Date</strong>
                  </Typography>
                  <FormControl
                    component='fieldset'
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <RadioGroup
                        aria-label='startingDate'
                        name='startingDate'
                        value={startingDate || '1 Month Notice'}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          control={<Radio name='startingDate' />}
                          label='1 Month Notice'
                          value='1 Month Notice'
                        />
                        <FormControlLabel
                          control={<Radio name='startingDate' />}
                          label='2 Month Notice'
                          value='2 Month Notice'
                        />
                        <FormControlLabel
                          control={<Radio name='startingDate' />}
                          label='Other'
                          value='Other'
                        />
                      </RadioGroup>
                      <TextField
                        value={otherStartingDate}
                        onChange={handleChange}
                        id='otherStartingDate'
                        name='otherStartingDate'
                        label='Other Starting Date'
                        fullWidth
                      ></TextField>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <strong>Expected Salary</strong>
                  </Typography>
                  <TextValidator
                    required
                    value={expectedSalary}
                    onChange={handleChange}
                    id='expectedSalary'
                    name='expectedSalary'
                    label='Your answer'
                    fullWidth
                    validators={['required', 'trim']}
                    errorMessages={['the field is required']}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <strong>Upload your updated resume here</strong>
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setOpenResume(true)}
                  >
                    {resumeDocument && resumeDocument.length > 0
                      ? 'Update'
                      : 'Add'}{' '}
                    your resume
                  </Button>
                  <DropzoneDialog
                    cancelButtonText={'cancel'}
                    submitButtonText={'submit'}
                    maxFileSize={5000000}
                    open={openResume}
                    onClose={() => setOpenResume(false)}
                    onSave={(files) => {
                      handleChange({
                        target: {name: 'resumeDocument', value: files},
                      });
                      setOpenResume(false);
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                  />
                  {resumeDocument && resumeDocument.length > 0 && (
                    <Fragment>
                      <Typography className={classes.typography}>
                        Document uploaded:
                      </Typography>
                      {resumeDocument.map((document, index) => {
                        return (
                          <Typography key={index} variant='body1'>
                            {document.name}
                          </Typography>
                        );
                      })}
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <strong>Upload your supporting document</strong>
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setOpenDocument(true)}
                  >
                    {supportingDocument && supportingDocument.length > 0
                      ? 'Update'
                      : 'Add'}{' '}
                    your supporting Document
                  </Button>
                  <DropzoneDialog
                    cancelButtonText={'cancel'}
                    submitButtonText={'submit'}
                    maxFileSize={5000000}
                    open={openDocument}
                    onClose={() => setOpenDocument(false)}
                    onSave={(files) => {
                      handleChange({
                        target: {name: 'supportingDocument', value: files},
                      });
                      setOpenDocument(false);
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                  />
                  {supportingDocument && supportingDocument.length > 0 && (
                    <Fragment>
                      <Typography className={classes.typography}>
                        Document uploaded:
                      </Typography>
                      {supportingDocument.map((document, index) => {
                        return (
                          <Typography key={index} variant='body1'>
                            {document.name}
                          </Typography>
                        );
                      })}
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {/* <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={(e) => handleBack()}
              >
                Kembali
              </Button> */}
              <Button type='submit' variant='contained' color='primary'>
                Selanjutnya
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Container>
    )
  );
};

CandidateApplication.propTypes = {
  candidates: PropTypes.object.isRequired,
  candidatesProfile: PropTypes.object.isRequired,
  positions: PropTypes.object.isRequired,
  candidateUpdate: PropTypes.func.isRequired,
  getPositions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
  candidatesProfile: state.candidatesProfile,
  positions: state.positions,
});

export default connect(mapStateToProps, {candidateUpdate, getPositions})(
  CandidateApplication
);
