import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import DateFormatted from '../../elements/DateFormatted';
import { getResultsByUser } from '../../../actions/results';
import { getUserById } from '../../../actions/users';
import { getDownloadLink } from '../../../actions/download';

import UserHeader from './UserHeader';

import array from '../../../utils/array';
const useStyles = makeStyles((theme) => ({
  textP: {
    color: green[400],
    fontWeight: 700,
  },
  textK: {
    color: red[400],
    fontWeight: 700,
  },
  grid: {},
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));
const ResultGrid = ({ data, index }) => {
  const classes = useStyles();
  const mainIndex = index;
  return (
    <Grid className={classes.grid} item xs={6}>
      {data.map((item, index) => {
        return (
          <Card className={classes.card} key={index}>
            <CardContent>
              <Grid container>
                <Grid item xs={1}>
                  <Typography variant='subtitle1'>
                    {mainIndex * data.length + index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  {Object.entries(item.originalAnswer).map(
                    (answer, answerIndex) => {
                      const colorClass =
                        answer[1] === item.selectedAnswerP
                          ? 'textP'
                          : answer[1] === item.selectedAnswerK
                          ? 'textK'
                          : '';
                      return (
                        <Typography
                          className={classes[colorClass]}
                          key={answerIndex}
                          variant='subtitle1'
                        >
                          {answer[1]}
                        </Typography>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Grid>
  );
};

const Results = (data) => {
  const results = array.sliceIntoChunks(data.data, 12);
  return (
    <Fragment>
      <Grid container spacing={2}>
        {results.map((item, index) => {
          return (
            <ResultGrid data={item} index={index} key={index}></ResultGrid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

const UsersResult = ({
  match,
  results: { results, loadingResult },
  users: { user, loadingUser },
  download: { download, loadingDownload },
  getResultsByUser,
  getUserById,
  getDownloadLink,
}) => {
  const classes = useStyles();
  const id = match.params.id;
  useEffect(() => {
    getResultsByUser(id);
    getUserById(id);
    getDownloadLink(id);
  }, [getResultsByUser, getUserById, getDownloadLink, id]);

  const onClickPrint = () => {
    window.open(download.url);
  };

  return loadingResult || loadingUser ? (
    ''
  ) : (
    <Fragment>
      <UserHeader user={user} />
      <Card>
        <CardHeader title={`${user.name}`} subheader={user.email}></CardHeader>
        <CardContent>
          <Typography variant='body1'>Umur: {user.age}</Typography>
          <Typography variant='body1'>Jenis Kelamin: {user.sex}</Typography>
          <Typography variant='body1'>
            Tanggal Test: <DateFormatted date={user.savedDate}></DateFormatted>
          </Typography>
          <hr />
        </CardContent>
        <CardContent>
          <Typography variant='h6'>Hasil Tes:</Typography>
          <Typography variant='body1'>
            Warna <span className={classes.textP}>hijau</span> menunjukan
            kalimat yang <strong>PALING menggambarkan</strong>
          </Typography>
          <Typography variant='body1'>
            Warna <span className={classes.textK}>merah</span> menunjukan
            kalimat yang <strong>PALING TIDAK menggambarkan</strong>
          </Typography>
          <Results data={results}></Results>
        </CardContent>
        <CardActions>
          <Button color='secondary' onClick={(e) => onClickPrint(e)}>
            Cetak
          </Button>
        </CardActions>
      </Card>
    </Fragment>
  );
};

UsersResult.propTypes = {
  results: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  download: PropTypes.object.isRequired,
  getResultsByUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  getDownloadLink: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  results: state.results,
  users: state.users,
  download: state.download,
});

export default connect(mapStateToProps, {
  getResultsByUser,
  getUserById,
  getDownloadLink,
})(UsersResult);
