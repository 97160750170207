import {SET_ALERT, REMOVE_ALERT, CLOSE_ALERT} from '../actions/types';
const notifications = [];

export default function alert(state = notifications, action) {
  switch (action.type) {
    case SET_ALERT:
      return [
        ...state,
        {
          key: action.key,
          ...action.notification,
        },
      ];
    case CLOSE_ALERT:
      return state.map((notification) =>
        action.dismissAll || notification.key === action.key
          ? {...notification, dismissed: true}
          : {...notification}
      );
    case REMOVE_ALERT:
      return state.filter((notification) => notification.key !== action.key);
    default:
      return state;
  }
}
