import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import RegisterStepper from './section/RegisterStepper';
import LoginForm from './section/LoginForm';

import {
  candidateRegister,
  candidateLoginV2,
  candidateConfirmation,
} from '../../../actions/users';

const useStyles = makeStyles((theme) => ({
  Link: {
    marginTop: theme.spacing(1),
  },
}));

const Register = ({
  candidateRegister,
  candidateLoginV2,
  candidateConfirmation,
  candidates: {candidate},
}) => {
  useEffect(() => {
    if (Object.keys(candidate).length !== 0) {
      window.location.href = '/candidate/dashboard';
    }
  }, [candidate]);
  const [state, setState] = useState('register');

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    code: '',
  });

  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onStateChange = (state) => {
    setState(state);
  };

  const onSubmit = (e) => {
    switch (state) {
      case 'register':
        candidateRegister(formData);
        break;
      case 'login':
        candidateLoginV2(formData);
        break;
      default:
        console.log('step not defined');
        break;
    }
  };
  const getContent = (state) => {
    switch (state) {
      case 'register':
        return (
          <RegisterStepper
            values={formData}
            handleRegister={candidateRegister}
            handleConfirmation={candidateConfirmation}
            handleChange={onFormChange}
            handleChangeState={onStateChange}
            classes={useStyles}
          ></RegisterStepper>
        );
      case 'login':
        return (
          <LoginForm
            values={formData}
            handleSubmit={onSubmit}
            handleChange={onFormChange}
            handleChangeState={onStateChange}
            classes={useStyles}
          ></LoginForm>
        );
      default:
        throw new Error('Unknwon step');
    }
  };

  return <Container>{getContent(state)}</Container>;
};

Register.propTypes = {
  candidateRegister: PropTypes.func.isRequired,
  candidateLoginV2: PropTypes.func.isRequired,
  candidateConfirmation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
});
export default connect(mapStateToProps, {
  candidateRegister,
  candidateLoginV2,
  candidateConfirmation,
})(Register);
