import {Auth} from 'aws-amplify';
import {setAlert} from './alert';
import s3Uploader from './s3Upload';
import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  GET_CANDIDATE,
  LOGOUT_CANDIDATE,
  GET_CANDIDATE_PROFILE,
} from './types';
import axios from 'axios';

export const addUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}users`,
      payload
    );
    dispatch({
      type: ADD_USER,
      payload: res.data.Item,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
    console.error(error);
  }
};

export const deleteUser = (payload) => async (dispatch) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}users/${payload.link}`
    );
    const resGets = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}users`
    );
    const data = resGets.data.items;
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
    console.error(error);
  }
};

export const editUser = (payload) => async (dispatch) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}users/${payload.link}`,
      payload
    );
    const resGets = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}users`
    );
    const data = resGets.data.items;
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const getUserById = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}users/${payload}`
    );
    dispatch({
      type: GET_USER,
      payload: res.data.data[0],
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
    console.error(error);
  }
};

export const getUsers = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}users`);
    const data = res.data.items;
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
    console.error(error);
  }
};

export const candidateLogin = (payload) => async (dispatch) => {
  try {
    dispatch(
      setAlert({
        message: 'Mencari data kandidat...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}candidates/${payload.id}`,
      payload
    );
    const data = res.data;
    if (data.isLoggedIn) {
      if (data.isTookTest) {
        dispatch(
          setAlert({
            message: `Anda sudah mengambil test pada ${data.data.savedDate}. Anda tidak dapat mengambil test lagi.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              autoHideDuration: 3000,
            },
          })
        );
      } else if (!data.isLinkValid) {
        dispatch(
          setAlert({
            message: `Link Anda sudah tidak valid, silakan meminta link baru untuk mengambil test DISC`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              autoHideDuration: 3000,
            },
          })
        );
      } else {
        const editPayload = {
          openDate: Date.now(),
        };
        await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}users/${payload.id}`,
          editPayload
        );
        dispatch({
          type: GET_CANDIDATE,
          payload: data.data,
        });
      }
    } else {
      dispatch(
        setAlert({
          message: `Token / Password tidak tepat`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
    console.error(error);
  }
};

export const candidateRegister = (payload) => async (dispatch) => {
  try {
    dispatch(
      setAlert({
        message: 'Menyimpan data registrasi...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    const {username, password} = payload;
    await Auth.signUp({
      username,
      password,
    });
    dispatch(
      setAlert({
        message:
          'Berhasil menyimpan, silakan cek email untuk mendapatkan kode verifikasi akun.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 5000,
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
        },
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const candidateLoginV2 = (payload) => async (dispatch) => {
  try {
    dispatch(
      setAlert({
        message: 'Mencari data kandidat...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    const {username, password} = payload;
    const user = await Auth.signIn(username, password);
    dispatch(
      setAlert({
        message: 'Selamat datang',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 3000,
        },
      })
    );
    dispatch({
      type: GET_CANDIDATE,
      payload: user,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const candidateLogout = () => async (dispatch) => {
  try {
    dispatch(
      setAlert({
        message: 'Keluar...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    await Auth.signOut();
    dispatch(
      setAlert({
        message: 'Anda berhasil keluar',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 3000,
        },
      })
    );
    dispatch({
      type: LOGOUT_CANDIDATE,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const candidateConfirmation = (payload) => async (dispatch) => {
  try {
    dispatch(
      setAlert({
        message: 'Melakukan verifikasi akun...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    const {username, password, code} = payload;
    await Auth.confirmSignUp(username, code);
    dispatch(
      setAlert({
        message: 'Verifikasi akun sukses.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 3000,
        },
      })
    );
    const user = await Auth.signIn(username, password);
    dispatch(
      setAlert({
        message: 'Selamat datang',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 3000,
        },
      })
    );
    dispatch({
      type: GET_CANDIDATE,
      payload: user,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const candidateCheck = () => async (dispatch) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}users/${user.username}`
      );
      dispatch({
        type: GET_CANDIDATE,
        payload: user,
      });
      dispatch({
        type: GET_CANDIDATE_PROFILE,
        payload: res.data.data[0],
      });
    } else {
      dispatch({
        type: LOGOUT_CANDIDATE,
        payload: user,
      });
    }
  } catch (error) {
    dispatch(
      setAlert({
        message: error,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const candidateUpdate = (payload) => async (dispatch) => {
  try {
    let res;
    const {candidate, newData, type} = payload;
    const dataToSave = newData;
    dataToSave.email = candidate.attributes.email;
    dataToSave.cognitoId = candidate.username;
    dispatch(
      setAlert({
        message: 'Menyimpan profil...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    switch (type) {
      case 'create':
        res = await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}users`,
          dataToSave
        );
        break;
      default:
        if (newData.resumeDocument) {
          let resumeLocation = [];
          await Promise.all(
            newData.resumeDocument.map(async (resume) => {
              const fileLocation = `public/document/${candidate.username}/${resume.name}`;
              const params = {
                key: fileLocation,
                body: resume,
              };
              await s3Uploader(params);
              resumeLocation.push(fileLocation);
            })
          );
          dataToSave.resumeDocument = resumeLocation;
        }
        if (newData.supportingDocument) {
          let supportLocation = [];
          await Promise.all(
            newData.supportingDocument.map(async (supporting) => {
              const fileLocation = `public/document/${candidate.username}/${supporting.name}`;
              const params = {
                key: fileLocation,
                body: supporting,
              };
              await s3Uploader(params);
              supportLocation.push(fileLocation);
            })
          );
          dataToSave.supportingDocument = supportLocation;
        }
        res = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}users/${candidate.username}`,
          dataToSave
        );
        break;
    }
    dispatch({
      type: GET_CANDIDATE_PROFILE,
      payload: res.data.data[0],
    });
    dispatch(
      setAlert({
        message: 'Profil tersimpan.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 3000,
        },
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const loginCheck = () => async (dispatch) => {
  dispatch(setAlert('Mengecek status login...', 'info'));
  dispatch(
    setAlert({
      message: 'Mengecek status login...',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'info',
        autoHideDuration: 3000,
      },
    })
  );
  const token = localStorage.getItem('candidate');
  if (token) {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}users/${token}`
    );
    dispatch({
      type: GET_CANDIDATE,
      payload: res.data.data[0],
    });
  } else {
    dispatch(
      setAlert({
        message: 'Silakan masukan password yang sudah di berikan.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
  }
};
