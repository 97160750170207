import React, {useEffect, Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {green, red} from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import CardActions from '@material-ui/core/CardActions';
import Link from '@material-ui/core/Link';

import DateFormatted from '../../elements/DateFormatted';
import DateOnlyFormat from '../../elements/DateOnlyFormat';

import array from '../../../utils/array';
import {postResult} from '../../../actions/results';
import {getQuestions} from '../../../actions/questions';
import {setAlert} from '../../../actions/alert';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
  },
  textP: {
    color: green[400],
  },
  textK: {
    color: red[400],
  },
  formControl: {
    minWidth: 240,
  },
}));

const PRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const KRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const CandidateDISC = ({
  source,
  candidates: {loadingCandidate, candidate},
  candidatesProfile: {profile, loadingProfile},
  questions: {questions, loadingQuestion},
  getQuestions,
  postResult,
  setAlert,
  handleNext,
  handleBack,
}) => {
  const classes = useStyles();
  let questionChunks;
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  if (questions) {
    questionChunks = array.sliceIntoChunks(questions, 12);
  }
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [selectedValue, setSelectedValue] = useState([]);

  const handleChange = (event) => {
    let key = event.target.name;
    let keyToCheck = key.includes('P') ? 'K' : 'P';
    let indexToCheck = key.replace('P', '').replace('K', '');
    keyToCheck = keyToCheck.concat(indexToCheck);
    if (selectedValue[keyToCheck] === event.target.value) {
      setAlert({
        message:
          'Anda harus memilih pernyataan yang berbeda untuk setiap nomor.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          autoHideDuration: 3000,
        },
      });
    } else {
      setSelectedValue({
        ...selectedValue,
        [key]: event.target.value,
      });
    }
  };
  const onClickSubmitTest = (e) => {
    e.preventDefault();
    postResult({
      result: selectedValue,
      candidate: candidate,
      questions: questions,
      profile: profile,
    });
    setEnableSubmit(false);
    handleNext();
  };
  return Object.keys(candidate).length !== 0 &&
    !loadingProfile &&
    Object.keys(profile).length !== 0 &&
    profile.name ? (
    <Container>
      {source != 'stepper' && (
        <Card variant='outlined' className={classes.card}>
          <CardHeader
            title={`${!loadingProfile && profile ? profile.name : ''}`}
            subheader={!loadingProfile && profile ? profile.email : ''}
          ></CardHeader>
          <CardContent>
            {!loadingProfile && profile && profile.savedDate ? (
              <Typography variant='body1'>
                <strong>
                  Anda sudah mengambil test pada:{' '}
                  {!loadingProfile && profile ? (
                    <DateFormatted date={profile.savedDate}></DateFormatted>
                  ) : (
                    ''
                  )}
                </strong>
              </Typography>
            ) : (
              ''
            )}
            <Fragment>
              <Typography variant='body1'>
                Tanggal Lahir:{' '}
                {!loadingProfile && profile && profile.birthDate ? (
                  <DateOnlyFormat date={profile.birthDate}></DateOnlyFormat>
                ) : (
                  ''
                )}
              </Typography>
              <Typography variant='body1'>
                Jenis Kelamin:{' '}
                {!loadingProfile && profile && profile.sex ? profile.sex : ''}
              </Typography>
            </Fragment>
          </CardContent>
        </Card>
      )}

      {!loadingProfile && !loadingQuestion && profile && profile.savedDate ? (
        profile.savedDate && (
          <Card className={classes.card}>
            <CardContent>
              <Typography variant='body1'>
                Anda sudah mengambil test pada:{' '}
                {!loadingProfile && profile && (
                  <strong>
                    <DateFormatted date={profile.savedDate}></DateFormatted>{' '}
                  </strong>
                )}
                . Silakan kembali ke{' '}
                <Link color='textSecondary' href='/candidate/dashboard'>
                  halaman utama
                </Link>
                .
              </Typography>
            </CardContent>
          </Card>
        )
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant='h6'>Instruksi</Typography>
            <Typography variant='body1' gutterBottom={true}>
              Setiap nomor di bawah ini memuat 4 (empat) kalimat yang merupakan
              representasi dari <strong>Gambaran Diri</strong>.
            </Typography>
            <Typography variant='body1'>Tugas anda adalah:</Typography>
            <Typography variant='body1'>
              1. Pilih pada kolom berwarna{' '}
              <strong className={classes.textP}>hijau</strong> di samping
              kalimat yang <strong>PALING menggambarkan</strong> diri anda
            </Typography>
            <Typography variant='body1' gutterBottom={true}>
              2. Pilih pada kolom huruf{' '}
              <strong className={classes.textK}>merah</strong> di samping
              kalimat yang <strong>PALING TIDAK menggambarkan</strong> diri anda
            </Typography>
            <Typography variant='body1' gutterBottom={true}>
              3. Jika dari empat pernyataan tersebut tidak ada yang
              menggambarkan diri anda, silakan pilih pernyataan yang{' '}
              <strong>paling</strong> mendekati gambaran diri anda
            </Typography>
            <Typography>
              <strong>PERHATIKAN: </strong>
              Setiap nomor hanya akan ada 1 pernyataan yang dapat dipilih di
              kolom <strong className={classes.textP}>Hijau</strong> dan 1
              pernyataan di kolom{' '}
              <strong className={classes.textK}>Merah</strong>
            </Typography>
            <hr></hr>
            <form onSubmit={(e) => onClickSubmitTest(e)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {questionChunks[0].map((item, index) => {
                    return (
                      <Card className={classes.card} key={index}>
                        <CardContent>
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.a}
                            onChange={handleChange}
                            value={item.a}
                            name={`P${index}`}
                            inputProps={{'aria-label': 'A'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.a}
                            onChange={handleChange}
                            value={item.a}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.a}}
                          />
                          {item.a}
                          <br />
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.b}
                            onChange={handleChange}
                            value={item.b}
                            name={`P${index}`}
                            inputProps={{'aria-label': 'B'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.b}
                            onChange={handleChange}
                            value={item.b}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.b}}
                          />
                          {item.b}
                          <br />
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.c}
                            onChange={handleChange}
                            value={item.c}
                            name={`P${index}`}
                            inputProps={{'aria-label': 'C'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.c}
                            onChange={handleChange}
                            value={item.c}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.c}}
                          />
                          {item.c}
                          <br />
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.d}
                            onChange={handleChange}
                            value={item.d}
                            color='default'
                            name={`P${index}`}
                            inputProps={{'aria-label': 'D'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.d}
                            onChange={handleChange}
                            value={item.d}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.d}}
                          />
                          {item.d}
                          <br />
                        </CardContent>
                      </Card>
                    );
                  })}
                </Grid>
                <Grid item xs={6}>
                  {questionChunks[1].map((item, index) => {
                    index = questionChunks[1].length + index;
                    return (
                      <Card className={classes.card} key={index}>
                        <CardContent>
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.a}
                            onChange={handleChange}
                            value={item.a}
                            name={`P${index}`}
                            inputProps={{'aria-label': 'A'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.a}
                            onChange={handleChange}
                            value={item.a}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.a}}
                          />
                          {item.a}
                          <br />
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.b}
                            onChange={handleChange}
                            value={item.b}
                            name={`P${index}`}
                            inputProps={{'aria-label': 'B'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.b}
                            onChange={handleChange}
                            value={item.b}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.b}}
                          />
                          {item.b}
                          <br />
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.c}
                            onChange={handleChange}
                            value={item.c}
                            name={`P${index}`}
                            inputProps={{'aria-label': 'C'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.c}
                            onChange={handleChange}
                            value={item.c}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.c}}
                          />
                          {item.c}
                          <br />
                          <PRadio
                            required={true}
                            checked={selectedValue[`P${index}`] === item.d}
                            onChange={handleChange}
                            value={item.d}
                            color='default'
                            name={`P${index}`}
                            inputProps={{'aria-label': 'D'}}
                          />
                          <KRadio
                            required={true}
                            checked={selectedValue[`K${index}`] === item.d}
                            onChange={handleChange}
                            value={item.d}
                            name={`K${index}`}
                            inputProps={{'aria-label': item.d}}
                          />
                          {item.d}
                          <br />
                        </CardContent>
                      </Card>
                    );
                  })}
                </Grid>
              </Grid>
              {profile.savedDate || !enableSubmit ? (
                ''
              ) : (
                <CardActions>
                  {/* <Button
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    onClick={(e) => handleBack()}
                  >
                    Kembali
                  </Button> */}
                  <Button variant='contained' color='primary' type='submit'>
                    Selesai
                  </Button>
                </CardActions>
              )}
            </form>
          </CardContent>
        </Card>
      )}
    </Container>
  ) : (!loadingProfile && !profile.name) ||
    (!loadingCandidate && Object.keys(candidate).length === 0) ? (
    <Container>
      <Card variant='outlined' className={classes.card}>
        <CardHeader title='Profil belum lengkap'></CardHeader>
        <CardContent>
          Silakan melengkapi profil dan aplikasi sebelum melakukan test DISC
        </CardContent>
      </Card>
    </Container>
  ) : (
    ''
  );
};

CandidateDISC.propTypes = {
  candidates: PropTypes.object.isRequired,
  candidatesProfile: PropTypes.object.isRequired,
  getQuestions: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  postResult: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
  candidatesProfile: state.candidatesProfile,
  questions: state.questions,
});

export default connect(mapStateToProps, {getQuestions, postResult, setAlert})(
  CandidateDISC
);
