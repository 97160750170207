import React, { useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import * as dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';

import {candidateUpdate} from '../../../actions/users';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    minWidth: 240,
  },
}));

const CandidateProfile = ({
  candidates: {candidate},
  candidatesProfile: {profile, loadingProfile},
  candidateUpdate,
  handleNext,
  handleBack,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: '',
    birthDate: new Date(),
    sex: '',
    currentAddress: '',
    email: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (profile && !loadingProfile) {
      if (Object.keys(profile).length !== 0) {
      }
      setFormData({
        name: profile && profile.name ? profile.name : '',
        currentAddress:
          profile && profile.currentAddress ? profile.currentAddress : '',
        email: profile && profile.email ? profile.email : '',
        phoneNumber: profile && profile.phoneNumber ? profile.phoneNumber : '',
        sex: profile && profile.sex ? profile.sex : '',
        birthDate:
          profile && profile.birthDate
            ? new Date(profile.birthDate)
            : new Date(),
      });
    }
  }, [profile]);

  const {name, birthDate, sex, currentAddress, phoneNumber} = formData;

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const handleDateChange = (e) => {
    handleChange({target: {type: 'date', name: 'birthDate', value: e.$d}});
  };
  const onSubmit = (e) => {
    candidateUpdate({type: 'create', candidate: candidate, newData: formData});
    handleNext();
  };

  return (
    <Container>
      <Card className={classes.card}>
        <ValidatorForm
          noValidate
          onSubmit={(e) => onSubmit(e)}
          onError={(errors) => console.log(errors)}
        >
          <CardHeader title='Personal Information'></CardHeader>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <strong>Full Name</strong>
                </Typography>
                <TextValidator
                  required
                  id='name'
                  name='name'
                  label='Your answer'
                  fullWidth
                  value={name}
                  onChange={handleChange}
                  validators={['required', 'trim']}
                  errorMessages={['the field is required']}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <strong>Birth Date</strong>
                </Typography>
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                  <DatePicker
                    fullWidth
                    autoOk={true}
                    variant='inline'
                    value={birthDate}
                    format='DD/MM/YYYY'
                    openTo='year'
                    label='Date of birth'
                    views={['year', 'month', 'date']}
                    disableFuture
                    onChange={(e) => handleDateChange(e)}
                  ></DatePicker>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <strong>Sex</strong>
                </Typography>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    fullWidth
                    labelid='sex-label'
                    id='sex'
                    value={sex}
                    onChange={handleChange}
                    name='sex'
                    validators={['required', 'trim']}
                    errorMessages={['the field is required']}
                  >
                    <MenuItem value='male'>Male</MenuItem>
                    <MenuItem value='female'>Female</MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <strong>Current Address</strong>
                </Typography>
                <TextValidator
                  required
                  id='currentAddress'
                  name='currentAddress'
                  label='Your answer'
                  fullWidth
                  value={currentAddress}
                  onChange={handleChange}
                  validators={['required', 'trim']}
                  errorMessages={['the field is required']}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <strong>Phone Number</strong>
                </Typography>
                <TextValidator
                  required
                  id='phoneNumber'
                  name='phoneNumber'
                  label='Your answer'
                  fullWidth
                  value={phoneNumber}
                  onChange={handleChange}
                  validators={['required', 'trim']}
                  errorMessages={['the field is required']}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
            >
              Selanjutnya
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Container>
  );
};

CandidateProfile.propTypes = {
  candidates: PropTypes.object.isRequired,
  candidatesProfile: PropTypes.object.isRequired,
  candidateUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
  candidatesProfile: state.candidatesProfile,
});

export default connect(mapStateToProps, {candidateUpdate})(CandidateProfile);
