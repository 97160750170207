import axios from 'axios';
import {setAlert} from './alert';
import {GET_DOWNLOAD, GET_DOCUMENTS} from './types';

export const getDownloadLink = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}download/${payload}`
    );
    dispatch({
      type: GET_DOWNLOAD,
      payload: res.data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const getDocumentLink = (payload) => async (dispatch) => {
  try {
    if (payload.resumeDocument && payload.resumeDocument.length > 0) {
      await Promise.all(
        payload.resumeDocument.map(async (resume) => {
          const res = await axios.post(
            `${process.env.REACT_APP_URL_BACKEND}download-document/`,
            {document: resume, type: 'resume'}
          );
          dispatch({
            type: GET_DOCUMENTS,
            payload: res.data,
          });
        })
      );
    }
    if (payload.supportingDocument && payload.supportingDocument.length > 0) {
      await Promise.all(
        payload.supportingDocument.map(async (support) => {
          const res = await axios.post(
            `${process.env.REACT_APP_URL_BACKEND}download-document/`,
            {document: support, type: 'support'}
          );
          dispatch({
            type: GET_DOCUMENTS,
            payload: res.data,
          });
        })
      );
    }
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};
