import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { getUserById } from '../../../actions/users';
import { getDocumentLink } from '../../../actions/download';
import DateOnlyFormat from '../../elements/DateOnlyFormat';
import CurrencyFormatted from '../../elements/CurrencyFormatted';

import UserHeader from './UserHeader';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: '0px',
    boxShadow: 'none',
  },
  formControl: {
    minWidth: 240,
  },
}));

const Document = ({ download, type }) => {
  return download && download.length > 0 ? (
    download.map((document, index) => {
      let fileName = document.document.split('/');
      fileName = fileName.pop();
      return document.type == type ? (
        <Fragment key={index}>
          <Link target='_blank' href={document.url} color='textPrimary'>
            {fileName}
          </Link>
          <br></br>
        </Fragment>
      ) : (
        <Fragment key={index}></Fragment>
      );
    })
  ) : (
    <Fragment></Fragment>
  );
};
const UsersEdit = ({
  users: { user, loadingUser },
  download: { documents, loadingDownload },
  match,
  getUserById,
  getDocumentLink,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    age: '',
    sex: '',
    link: '',
    position: '',
    source_LinkedIn: false,
    source_Social_Media: false,
    source_Company_Website: false,
    source_Internal_Employee: false,
    source_Job_Platform: false,
    startingDate: '1 Month Notice',
    expectedSalary: '',
    source: [],
    resumeDocument: [],
    supportingDocument: [],
    source_Other: '',
    otherStartingDate: '',
  });

  const id = match.params.id;
  useEffect(() => {
    if (!formData.link) {
      getUserById(id);
      setFormData({
        link: loadingUser || !user.link ? '' : user.link,
        name: loadingUser || !user.name ? '' : user.name,
        email: loadingUser || !user.email ? '' : user.email,
        birthDate: loadingUser || !user.birthDate ? '' : user.birthDate,
        sex: loadingUser || !user.sex ? 'male' : user.sex,
        phoneNumber: loadingUser || !user.phoneNumber ? '' : user.phoneNumber,
        currentAddress:
          loadingUser || !user.currentAddress ? '' : user.currentAddress,
        position: loadingUser || !user.position ? '' : user.position,
        source_LinkedIn:
          loadingUser || !user.source_LinkedIn ? false : user.source_LinkedIn,
        source_Social_Media:
          loadingUser || !user.source_Social_Media
            ? false
            : user.source_Social_Media,
        source_Company_Website:
          loadingUser || !user.source_Company_Website
            ? false
            : user.source_Company_Website,
        source_Internal_Employee:
          loadingUser || !user.source_Internal_Employee
            ? false
            : user.source_Internal_Employee,
        source_Job_Platform:
          loadingUser || !user.source_Job_Platform
            ? false
            : user.source_Job_Platform,
        startingDate:
          loadingUser || !user.startingDate
            ? '1 Month Notice'
            : user.startingDate,
        expectedSalary:
          loadingUser || !user.expectedSalary ? '' : user.expectedSalary,
        source: loadingUser || !user.source ? '' : user.source,
        resumeDocument:
          loadingUser || !user.resumeDocument ? [] : user.resumeDocument,
        supportingDocument:
          loadingUser || !user.supportingDocument
            ? []
            : user.supportingDocument,
        source_Other:
          loadingUser || !user.source_Other ? '' : user.source_Other,
        otherStartingDate:
          loadingUser || !user.otherStartingDate ? '' : user.otherStartingDate,
      });
    }
  }, [getUserById, id, user]);

  useEffect(() => {
    getDocumentLink(user);
  }, [user]);
  const onFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <Fragment>
      {!loadingUser && <UserHeader user={user} />}
      <Card className={classes.card}>
        <CardHeader title='Personal Information'></CardHeader>
        {!loadingUser ? (
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Typography variant='body1'>Full Name:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1'>{user.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'>Birth Date:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1'>
                  {user.birthDate ? (
                    <DateOnlyFormat date={user.birthDate}></DateOnlyFormat>
                  ) : (
                    ''
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'>Sex:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1'>{user.sex}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'>Current Address:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1'>{user.currentAddress}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'>Email:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1'>{user.email}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'>Phone Number:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1'>{user.phoneNumber}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          ''
        )}
      </Card>
      <Card className={classes.card}>
        <CardHeader title='Application Form'></CardHeader>
        {!loadingUser ? (
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant='body1'>Apply for position:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='body1'>{user.position}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>
                  How did you hear about us:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {user.source_Other ? (
                  <Typography variant='body1'>{user.source_Other}</Typography>
                ) : (
                  ''
                )}
                <FormControl
                  component='fieldset'
                  className={classes.formControl}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.source_LinkedIn}
                          name='source_LinkedIn'
                          value='LinkedIn'
                          onChange={onFormChange}
                        />
                      }
                      label='LinkedIn'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.source_Social_Media}
                          name='source_Social_Media'
                          value='Social Media'
                          onChange={onFormChange}
                        />
                      }
                      label='Social Media'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.source_Company_Website}
                          name='source_Company_Website'
                          value='Company Website'
                          onChange={onFormChange}
                        />
                      }
                      label='Company Website'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.source_Internal_Employee}
                          name='source_Internal_Employee'
                          value='Internal Employee'
                          onChange={onFormChange}
                        />
                      }
                      label='Internal Employee'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.source_Job_Platform}
                          name='source_Job_Platform'
                          value='Job Platform (Jobstreet, Techinasia, JobsDB, etc)'
                          onChange={onFormChange}
                        />
                      }
                      label='Job Platform (Jobstreet, Techinasia, JobsDB, etc)'
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>
                  Available Starting Date:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='body1'>
                  {user.otherStartingDate
                    ? user.otherStartingDate
                    : user.startingDate}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>Expected Salary:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='body1'>
                  <CurrencyFormatted salary={user.expectedSalary} />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>Resume Document:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Document download={documents} type='resume'></Document>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>Supporting Document:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Document download={documents} type='support'></Document>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          ''
        )}
      </Card>
    </Fragment>
  );
};

UsersEdit.propTypes = {
  getUserById: PropTypes.func.isRequired,
  getDocumentLink: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  download: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
  download: state.download,
});

export default connect(mapStateToProps, { getUserById, getDocumentLink })(
  UsersEdit
);
