// import uuid from 'uuid';
import {SET_ALERT, REMOVE_ALERT, CLOSE_ALERT} from './types';

export const setAlert = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: SET_ALERT,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeAlert = (key) => ({
  type: CLOSE_ALERT,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeAlert = (key) => ({
  type: REMOVE_ALERT,
  key,
});
