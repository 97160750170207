import React, {Fragment, useState} from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import RegisterForm from '../section/RegisterForm';
import ConfirmationForm from '../section/ConfirmationForm';

const steps = ['Register', 'Verify your account'];

const RegisterStepper = ({
  values,
  handleRegister,
  handleConfirmation,
  handleChange,
  handleChangeState,
  classes,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleSubmit = () => {
    switch (activeStep) {
      case 0:
        handleRegister(values);
        setActiveStep(activeStep + 1);
        break;
      case 1:
        handleConfirmation(values);
        break;
      default:
        console.log('method not implemented.');
        break;
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RegisterForm
            values={values}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            classes={classes}
          />
        );
      case 1:
        return (
          <ConfirmationForm
            values={values}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            classes={classes}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <Card variant='outlined'>
      <CardHeader
        title={activeStep === 0 ? 'Sign Up' : 'Verifikasi Akun'}
        subheader={
          activeStep === 0
            ? 'Sebelum mengisi data, silakan membuat akun terlebih dahulu. Isi form berikut untuk mendapatkan kode verifikasi.'
            : `Silakan masukan kode verifikasi yang dikirimkan ke email.`
        }
      ></CardHeader>
      <CardContent>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fragment>{getStepContent(activeStep)}</Fragment>
        <Grid className={classes().Link} container>
          <Grid item xs={12}>
            <Link
              href='#'
              onClick={(e) => handleChangeState('login')}
              variant='body1'
              color='textSecondary'
            >
              Sudah punya akun? Login
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

RegisterStepper.propTypes = {};

export default RegisterStepper;
