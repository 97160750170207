import React, {Fragment} from 'react';

import Button from '@material-ui/core/Button';

import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const ConfirmationForm = ({values, handleSubmit, handleChange, classes}) => {
  return (
    <Fragment>
      <ValidatorForm
        noValidate
        onSubmit={(e) => handleSubmit(e)}
        onError={(errors) => console.log(errors)}
      >
        <TextValidator
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='code'
          label='Kode verifikasi'
          name='code'
          type='code'
          value={values.code}
          autoFocus
          onChange={(e) => handleChange(e)}
          validators={['required', 'trim']}
          errorMessages={['the field is required']}
        />
        <div className={classes.buttons}>
          <Button
            fullWidth
            type='submit'
            variant='contained'
            color='primary'
            className={classes().submit}
          >
            Login
          </Button>
        </div>
      </ValidatorForm>
    </Fragment>
  );
};

ConfirmationForm.propTypes = {};

export default ConfirmationForm;
