import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import tableIcons from '../elements/TableIcons';

import { getQuestions } from '../../actions/questions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    border: 'none',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Questions = ({
  questions: { questions, loadingQuestion },
  getQuestions,
}) => {
  const classes = useStyles();
  let items = [];
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);
  if (!loadingQuestion && questions.length > 0) {
    questions.forEach((item, index) => {
      items.push({
        name: `Pertanyaan ke ${index + 1}`,
        detail: item,
      });
    });
  }
  return (
    <Fragment>
      <MaterialTable
        options={{ pageSize: 4, pageSizeOptions: [8, 16, 24] }}
        icons={tableIcons}
        columns={[
          {
            title: 'Question',
            field: 'name',
            headerStyle: { fontWeight: 'bold' },
          },
        ]}
        data={items}
        detailPanel={(rowData) => {
          const dense = true;
          return (
            <Fragment>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Paper elevation={0} className={classes.paper}>
                    <List dense={dense}>
                      <ListItem>
                        <ListItemText secondary={`1. ${rowData.detail.a}`} />
                      </ListItem>
                      <ListItem>
                        <ListItemText secondary={`2. ${rowData.detail.b}`} />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={classes.paper}>
                    <ListItem>
                      <ListItemText secondary={`3. ${rowData.detail.c}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary={`4. ${rowData.detail.d}`} />
                    </ListItem>
                  </Paper>
                </Grid>
              </Grid>
            </Fragment>
          );
        }}
        title='Questions List'
      />
    </Fragment>
  );
};

Questions.propTypes = {
  getQuestions: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  questions: state.questions,
});

export default connect(mapStateToProps, { getQuestions })(Questions);
