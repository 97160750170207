import {GET_CANDIDATE_PROFILE} from '../actions/types';

const initialState = {
  profile: {},
  loadingProfile: true,
  error: {},
};

export default function candidatesProfile(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_CANDIDATE_PROFILE:
      return {
        ...state,
        profile: payload ? payload : {},
        loadingProfile: false,
      };
    default:
      return state;
  }
}
