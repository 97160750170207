import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import MaterialTable from 'material-table';

import tableIcons from '../elements/TableIcons';
import DateFormatted from '../elements/DateFormatted';
import DateOnlyFormat from '../elements/DateOnlyFormat';
import CurrencyFormatted from '../elements/CurrencyFormatted';
import { setAlert } from '../../actions/alert';
import { getUsers, deleteUser } from '../../actions/users';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    boxShadow: 'none',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DetailPanel = ({ rowData }) => {
  const styles = useStyles();

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography gutterBottom>User Detail</Typography>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Paper className={styles.paper}>Email:</Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper className={styles.paper}>{rowData.email}</Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper className={styles.paper}>Phone Number:</Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper className={styles.paper}>{rowData.phoneNumber}</Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper className={styles.paper}>Tanggal Lahir:</Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper className={styles.paper}>
              <DateOnlyFormat date={rowData.birthDate}></DateOnlyFormat>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper className={styles.paper}>Jenis kelamin:</Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper className={styles.paper}>{rowData.sex}</Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper className={styles.paper}>Tanggal dibuat:</Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper className={styles.paper}>
              <DateFormatted date={rowData.dateCreated}></DateFormatted>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper className={styles.paper}>Tanggal test selesai:</Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper className={styles.paper}>
              {rowData.savedDate ? (
                <DateFormatted date={rowData.savedDate}></DateFormatted>
              ) : (
                ''
              )}
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Dashboard = ({
  users: { users, loadingUser },
  getUsers,
  deleteUser,
  setAlert,
}) => {
  const history = useHistory();
  const [DataToDelete, setDataToDelete] = useState({});
  const [OpenDialog, setOpenDialog] = useState(false);
  const [value, setValue] = useState(0);
  let items = [];
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (!loadingUser && users.length > 0) {
    users.forEach((item, index) => {
      items.push(item);
    });
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const onClickDelete = (data) => {
  //   setOpenDialog(true);
  //   setDataToDelete(data);
  // };
  const onClickEdit = (data) => {
    // history.push(`/users/edit/${data.link}`);
    window.location.href = `/users/edit/${data.link}`;
  };
  const onClickOpen = (data) => {
    history.push(`/results/${data.link}`);
  };
  const onDialogClose = () => {
    setDataToDelete({});
    setOpenDialog(false);
  };
  const onDialogConfirm = () => {
    deleteUser(DataToDelete);
    setOpenDialog(false);
    setDataToDelete({});
  };

  const tableOptions = {
    pageSize: 15,
    pageSizeOptions: [5, 10, 15, 20],
    actionsColumnIndex: -1,
  };

  const tableActions = [
    (rowData) => ({
      icon: tableIcons.View,
      tooltip: 'Update Kandidat',
      onClick: (event, rowData) => onClickEdit(rowData),
    }),
    (rowData) => ({
      icon: tableIcons.FolderOpenIcon,
      tooltip: 'Lihat Hasil Test',
      onClick: (event, rowData) => onClickOpen(rowData),
      disabled:
        rowData.savedDate === undefined || rowData.savedDate === 'undefined',
    }),
  ];

  return (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label='Kandidat tab'
        >
          <Tab label='Overview' {...a11yProps(0)} />
          <Tab label='All Columns' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MaterialTable
          options={tableOptions}
          icons={tableIcons}
          columns={[
            {
              title: 'Nama',
              field: 'name',
              headerStyle: { fontWeight: 'bold' },
            },
            {
              title: 'Email',
              field: 'email',
              headerStyle: { fontWeight: 'bold' },
            },
          ]}
          data={items}
          detailPanel={(rowData) => {
            return <DetailPanel rowData={rowData}></DetailPanel>;
          }}
          actions={tableActions}
          title='Data Kandidat Sekilas'
        ></MaterialTable>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialTable
          options={tableOptions}
          icons={tableIcons}
          columns={[
            {
              title: 'Nama',
              field: 'name',
              headerStyle: { fontWeight: 'bold' },
            },
            {
              title: 'Position',
              field: 'position',
              headerStyle: { fontWeight: 'bold' },
            },
            {
              title: 'Salary',
              field: 'expectedSalary',
              headerStyle: { fontWeight: 'bold' },
              render: (rowData) => (
                <CurrencyFormatted salary={rowData.expectedSalary} />
              ),
            },
            {
              title: 'Available Starting Date',
              field: 'startingDate',
              headerStyle: { fontWeight: 'bold' },
            },
          ]}
          data={items}
          detailPanel={(rowData) => {
            return <DetailPanel rowData={rowData}></DetailPanel>;
          }}
          actions={tableActions}
          title='Data Kandidat Penuh'
        ></MaterialTable>
      </TabPanel>

      <Dialog
        open={OpenDialog}
        onClose={onDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Hapus kandidat?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Anda yakin ingin menghapus kandidat ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color='secondary'>
            Batalkan
          </Button>
          <Button onClick={onDialogConfirm} color='secondary'>
            Ya hapus
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

Dashboard.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, { getUsers, deleteUser, setAlert })(
  Dashboard
);
