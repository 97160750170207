import {combineReducers} from 'redux';

import alert from './alert';
import auth from './auth';
import admins from './admins';
import candidates from './candidates';
import candidatesProfile from './candidatesProfile';
import download from './download';
import positions from './positions';
import questions from './questions';
import results from './results';
import users from './users';
export default combineReducers({
  admins,
  alert,
  auth,
  candidates,
  candidatesProfile,
  download,
  positions,
  questions,
  results,
  users,
});
