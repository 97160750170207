import axios from 'axios';
import {setAlert} from './alert';
import {GET_RESULTS} from './types';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export const getResultsByUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}results/${payload}`
    );
    dispatch({
      type: GET_RESULTS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};

export const postResult = (payload) => async (dispatch) => {
  try {
    dispatch(
      setAlert({
        message: 'Menyimpan jawaban...',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'info',
          autoHideDuration: 3000,
        },
      })
    );
    const {candidate, questions, result, profile} = payload;
    const payloadToSend = {
      questions: questions,
      result: result,
    };
    await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}results/${candidate.username}`,
      payloadToSend
    );
    const editPayload = {
      savedDate: Date.now(),
      age: dayjs(profile.birthDate).toNow(true),
    };
    await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}users/${candidate.username}`,
      editPayload
    );
    dispatch(
      setAlert({
        message: 'Jawaban telah tersimpan. Terima kasih.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 3000,
        },
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      })
    );
  }
};
