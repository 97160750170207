import React, {useEffect, useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';

import CandidateProfile from './CandidateProfile';
import CandidateApplication from './CandidateApplication';
import CandidateDISC from './CandidateDISC';

import {candidateLogout} from '../../../actions/users';
import {getQuestions} from '../../../actions/questions';

import DateFormatted from '../../elements/DateFormatted';
import DateOnlyFormat from '../../elements/DateOnlyFormat';
import {CardActions} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {marginTop: theme.spacing(1.5)},
  cardTop: {marginBottom: theme.spacing(1.5)},
  paper: {},
}));

const steps = ['Lengkapi Data Profil', 'Lengkapi Data Aplikasi', 'DISC Test'];

const CandidateDashboard = ({
  candidates: {candidate},
  candidatesProfile: {profile, loadingProfile},
  getQuestions,
  candidateLogout,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);
  useEffect(() => {
    if (!loadingProfile && profile && profile.savedDate) {
      setActiveStep(3);
    }
  }, [profile, loadingProfile, candidate]);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleLogout = () => {
    candidateLogout();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CandidateProfile
            handleNext={handleNext}
            handleBack={handleBack}
          ></CandidateProfile>
        );
      case 1:
        return (
          <CandidateApplication
            handleNext={handleNext}
            handleBack={handleBack}
          ></CandidateApplication>
        );
      case 2:
        return (
          <CandidateDISC
            handleNext={handleNext}
            handleBack={handleBack}
            source={'stepper'}
          ></CandidateDISC>
        );
      case 3:
        return (
          <Fragment>
            <Card className={classes.card}>
              <CardHeader title='Terima kasih telah berpartisipasi dalam proses rekruitmen.'></CardHeader>
              <CardContent>
                <Typography variant='subtitle1'>
                  Tim kami akan memproses data dan aplikasi anda.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  onClick={(e) => handleLogout()}
                >
                  Keluar
                </Button>
              </CardActions>
            </Card>
          </Fragment>
        );
      default:
        throw new Error('Unknown step');
    }
  };
  return (
    !loadingProfile && (
      <Container>
        <Card className={classes.cardTop}>
          <CardHeader
            title={`Halo ${
              !loadingProfile &&
              Object.keys(profile).length !== 0 &&
              profile.name
                ? profile.name
                : ''
            }`}
          ></CardHeader>
          <CardContent>
            {activeStep !== steps.length - 1 ? (
              !loadingProfile && profile && profile.savedDate ? (
                <Typography variant='body1'>
                  Anda sudah mengambil test pada:{' '}
                  {!loadingProfile && profile && (
                    <strong>
                      <DateFormatted date={profile.savedDate}></DateFormatted>{' '}
                    </strong>
                  )}
                  .
                </Typography>
              ) : (
                <Typography variant='body1' color='textPrimary'>
                  Untuk mengikuti proses rekruitmen, silakan lengkapi profil dan
                  form aplikasi sebelum melakukan test DISC.
                </Typography>
              )
            ) : (
              <Fragment>
                <Typography variant='body1'>
                  Tanggal Lahir:{' '}
                  {!loadingProfile && profile && profile.birthDate ? (
                    <DateOnlyFormat date={profile.birthDate}></DateOnlyFormat>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography variant='body1'>
                  Jenis Kelamin:{' '}
                  {!loadingProfile && profile && profile.sex ? profile.sex : ''}
                </Typography>
              </Fragment>
            )}
          </CardContent>
        </Card>
          <Fragment>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Fragment>{getStepContent(activeStep)}</Fragment>
        </Fragment>
      </Container>
    )
  );
};

CandidateDashboard.propTypes = {
  candidates: PropTypes.object.isRequired,
  candidatesProfile: PropTypes.object.isRequired,
  getQuestions: PropTypes.func.isRequired,
  candidateLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
  candidatesProfile: state.candidatesProfile,
});

export default connect(mapStateToProps, {getQuestions, candidateLogout})(
  CandidateDashboard
);
