import {
  GET_POSITIONS,
  GET_POSITION,
  ADD_POSITION,
} from '../actions/types';

const initialState = {
  position: {},
  positions: [],
  loadingPosition: true,
  error: {},
};

export default function positions(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_POSITIONS:
      return {
        ...state,
        position: {},
        positions: payload,
        loadingPosition: false,
      };
    case GET_POSITION:
      return {
        ...state,
        position: payload,
        positions: [],
        loadingPosition: false,
      };
    case ADD_POSITION:
      return {
        ...state,
        position: payload,
        positions: [payload, ...state.positions],
        loadingPosition: false,
      };
    default:
      return state;
  }
}
