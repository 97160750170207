/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:056e1ca2-6a64-4936-a974-8c04a71b1af1",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_7m8PYbC5A",
    "aws_user_pools_web_client_id": "26uv021hbvkb0s1jjm163vi01t",
    "oauth": {}
};


export default awsmobile;
