import {GET_RESULTS, ADD_RESULT} from '../actions/types';

const initialState = {
  result: {},
  results: {},
  loadingResult: true,
  error: {},
};

export default function results(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_RESULTS:
      return {
        ...state,
        result: {},
        results: payload,
        loadingResult: false,
      };
    case ADD_RESULT:
      return {
        ...state,
        result: payload,
        results: [payload, ...state.results],
        loadingResult: false,
      };
    default:
      return state;
  }
}
