import {GET_QUESTIONS} from '../actions/types';

const initialState = {
  question: {},
  questions: [],
  loadingQuestion: true,
  error: {},
};

export default function questions(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_QUESTIONS:
      return {
        ...state,
        question: {},
        questions: payload,
        loadingQuestion: false,
      };
    default:
      return state;
  }
}
