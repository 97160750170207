import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity');
const {
  fromCognitoIdentityPool,
} = require('@aws-sdk/credential-provider-cognito-identity');
const s3Uploader = async (parameters) => {
  const s3Config = {
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({
        region: process.env.REACT_APP_S3_REGION,
      }),
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY, // IDENTITY_POOL_ID e.g., eu-west-1:xxxxxx-xxxx-xxxx-xxxx-xxxxxxxxx
    }),
    region: process.env.REACT_APP_S3_REGION,
  };
  const s3Client = new S3Client(s3Config);
  const s3Input = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: parameters.key,
    Body: parameters.body,
    ContentDisposition: 'inline',
    ContentType: 'application/pdf',
  };
  const command = new PutObjectCommand(s3Input);
  return await s3Client.send(command);
};

export default s3Uploader;
